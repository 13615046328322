import { atom } from "jotai";
import { ReactNode } from "react";

export type ModalDeleteGlobalProps = {
  isOpen: boolean;
  illustration?: ReactNode;
  title: string;
  description: string | ReactNode;
  confirmText?: string;
  onConfirm: () => void;
  cancelText?: string;
  onClose?: () => void;
  loading?: boolean;
};

export const modalDeleteGlobalValue = {
  isOpen: false,
  title: "",
  illustration: undefined,
  description: "",
  confirmText: "Hapus",
  onConfirm: () => {},
  cancelText: "Batal",
  onClose: () => {},
  loading: false,
};

const modalDeleteGlobalAtom = atom<ModalDeleteGlobalProps>(
  modalDeleteGlobalValue,
);

export default modalDeleteGlobalAtom;
